export const prefix = '/api/bo'

export const prepareUrl = (url, options) => {
  let _url = url
  Object.keys(options).forEach((k) => {
    const rx = new RegExp(`{${k}}`, 'i')
    _url = _url.replace(rx, options[k])
  })
  return _url
}

export const uri = {
  me: `${prefix}/me`,
  locked: `${prefix}/locked`,
  markets: `${prefix}/markets`,
  dashboard: {
    turnover: `${prefix}/dash/turnover`,
    clientTotals: `${prefix}/dash/client-totals`,
    twoDaysTotal: `${prefix}/dash/2d-total`,
    twoDaysChart: `${prefix}/dash/2d-chart`,
    sevenDays: `${prefix}/dash/7d`,
    sevenDaysTotal: `${prefix}/dash/7d-total`,
    sevenDaysChart: `${prefix}/dash/7d-chart`,
    thirtyDays: `${prefix}/dash/30d`,
    thirtyDaysTotal: `${prefix}/dash/30d-total`,
    thirtyDaysChart: `${prefix}/dash/30d-chart`,
    monthly: `${prefix}/dash/{year}/{month}`,
    monthlyTotal: `${prefix}/dash/total/{year}/{month}`,
    monthlyChart: `${prefix}/dash/chart/{year}/{month}`
  },
  affiliateTest: `${prefix}/affiliates/test`,
  affiliateRecognize: `${prefix}/affiliates/{id}/recognize`,
  affiliateAggregateRecognized: `${prefix}/affiliates/{id}/aggregate-recognized`,
  affiliateCancelAggregateRecognized: `${prefix}/affiliates/{id}/cancel-aggregate-recognized`,
  media: {
    validateAll: `${prefix}/media/validate/{id}`,
    validate: `${prefix}/media/{id}/validate`,
    transform: `${prefix}/media/{id}/transform/{action}`,
    addAvatar: `${prefix}/media/{id}/add-avatar`,
    add: `${prefix}/media/{id}/add`,
    addLarge: `${prefix}/media/{id}/add-lg`,
    update: `${prefix}/media/{id}/update`,
    delete: `${prefix}/media/{id}/delete`
  },
  customers: {
    paymentApprove: `${prefix}/customers/{userId}/payments/{id}/approve`,
    noteAdd: `${prefix}/customers/{userId}/notes/add`,
    noteDelete: `${prefix}/customers/{userId}/notes/delete`,
    lockPassword: `${prefix}/customers/{userId}/lock-pwd`,
    unlockPassword: `${prefix}/customers/{userId}/unlock-pwd`,
    permanentDeleteList: `${prefix}/customers/{userId}/permanent-list`,
    permanentDelete: `${prefix}/customers/{userId}/permanent`,
    verifyEmail: `${prefix}/customers/{userId}/verify-email`,
    unverifyEmail: `${prefix}/customers/{userId}/unverify-email`,
    verifyMobile: `${prefix}/customers/{userId}/verify-mobile`,
    unverifyMobile: `${prefix}/customers/{userId}/unverify-mobile`,
    chartCredits: `${prefix}/customers/{userId}/chart-credits`,
    approveFreeLikeMessage: `${prefix}/customers/{userId}/free-like-message-approve`,
    declineFreeLikeMessage: `${prefix}/customers/{userId}/free-like-message-decline`,
    updateFreeLikeMessage: `${prefix}/customers/{userId}/free-like-message-update`,
    refreshSms: `${prefix}/customers/{userId}/sms/refresh`,
    batchSms: `${prefix}/customers/{userId}/sms/{batchId}`
  },
  support: {
    index: `${prefix}/support`,
    show: `${prefix}/support/{id}`,
    read: `${prefix}/support/{id}/read`,
    messages: `${prefix}/support/{id}/messages`,
    messagesDelete: `${prefix}/support/{id}/messages/{messageId}`
  },
  task: {
    assign: `${prefix}/tasks/{id}/assign`,
    cancelAssignment: `${prefix}/tasks/{id}/cancel-assignment`,
    batch: `${prefix}/tasks/batch/{id}/update`
  },
  talks: {
    enableFeedback: `${prefix}/talks/enable-feedback/{id}`,
    deleteFeedback: `${prefix}/talks/delete-feedback/{id}`,
    refundTalk: `${prefix}/talks/refund-talk/{id}`
  },
  settings: {
    payments: {
      base: `${prefix}/settings/payments`,
      data: `${prefix}/settings/payments/create`,
      modify: `${prefix}/settings/payments/{id}`
    },
    messages: {
      scamInfo: `${prefix}/settings/messages/scam-info`
      // , scamProcess: `${prefix}/settings/messages/scam-process`
    },
    hiddenProfiles: {
      index: `${prefix}/settings/hidden-profiles`
    }
  },
  reports: {
    users: {
      filterData: `${prefix}/reports/users/filter-data`,
      reportScv: `${prefix}/reports/users/csv`,
      reportXls: `${prefix}/reports/users/xls`,
      reportChartData: `${prefix}/reports/users-chart`,
      reportItemsData: `${prefix}/reports/users-items`
    },
    hostess: {
      filterData: `${prefix}/reports/hostess/filter-data`,
      reportScv: `${prefix}/reports/hostess/csv`,
      reportXls: `${prefix}/reports/hostess/xls`,
      reportData: `${prefix}/reports/hostess`
    },
    hostessChat: {
      filterData: `${prefix}/reports/hostess-chat/filter-data`,
      reportScv: `${prefix}/reports/hostess-chat/csv`,
      reportXls: `${prefix}/reports/hostess-chat/xls`,
      reportData: `${prefix}/reports/hostess-chat`
    },
    cities: {
      filterData: `${prefix}/reports/cities/filter-data`,
      reportScv: `${prefix}/reports/cities/csv`,
      reportXls: `${prefix}/reports/cities/xls`,
      reportData: `${prefix}/reports/cities`
    },
    payments: {
      filterData: `${prefix}/reports/payments/filter-data`,
      reportScv: `${prefix}/reports/payments/csv`,
      reportXls: `${prefix}/reports/payments/xls`,
      reportData: `${prefix}/reports/payments`
    }
  },
  search: {
    city: `${prefix}/search/city`,
    nationality: `${prefix}/search/nationality`,
    language: `${prefix}/search/language`,
    service: `${prefix}/search/service`
  },
  // for each repo we can specify methods:
  // index, show, create, update, delete or * to use all
  repository: {
    affiliates: ['*', 'filterData', 'search', { users: ['index'] }],
    tasks: ['index', 'update', 'filterData'],
    customers: [
      'index', 'show', 'edit', 'update', 'delete', 'filterData', 'search',
      {
        talks: ['index'],
        payments: ['index', 'filterData'],
        transactions: ['index', 'filterData'],
        reports: ['index', 'update', 'delete', 'filterData'],
        feedbacks: ['index', 'filterData'],
        invites: ['index', 'filterData'],
        notes: ['index', 'filterData'],
        sms: ['index', 'store', 'filterData']
      }
    ],
    users: ['*', 'filterData', 'search'],
    faqs: ['*', 'filterData', 'search'],
    // payments: ['index', 'filterData'],
    messages: ['index', 'filterData', 'search'],
    notes: ['index', 'filterData', 'search'],
    promotions: ['index', 'create', 'store'],
    tec: ['show', 'edit', 'update'],
    talks: ['filterData', 'index', 'show', 'edit', { messages: ['index', 'update', 'delete'] }],
    // support: ['index', 'show', {
    //   messages: ['index', 'store', 'update', 'delete']
    // }],
    settings: ['index', 'store', {
      payments: ['index', 'show', 'create', 'edit', 'store', 'update', 'delete', 'filterData']
    }]
  }
}
